export const setupColor = (key, color) => {
  document.documentElement.style.setProperty(`--${key}`, color);
  document.documentElement.style.setProperty(`--ion-color-${key}`, color);
};

export const setupTitle = (app_name, app_tagline) => {
  let title = `${app_name}`;
  if(app_tagline) {
    title += `| ${app_tagline}`;
  }
  document.title = title;
};

export const handleMetaTags = (config) => {
  const {
    favicon,
    app_css,
    tagline,
    app_name,
    S3_ENDPOINT,
    social_media_image,
    head_tracking_codes,
    body_tracking_codes,
  } = config;

  if (favicon) {
    const src = `${S3_ENDPOINT}/${favicon}`;

    const apple = document.querySelector("link[rel='apple-touch-icon']");
    if (apple && apple !== null) apple.href = src;
    
    const link = document.querySelector("link[rel~='icon']");
    if (link && link !== null) link.href = src;
  }
  
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.origin);

  document.title = `${app_name}`;
  document
    .querySelector('meta[itemprop="name"]')
    .setAttribute("content", app_name);
  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", app_name);
  document
    .querySelector('meta[name="twitter:title"]')
    .setAttribute("content", app_name);

  if (tagline) {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", tagline);
    document
      .querySelector('meta[itemprop="description"]')
      .setAttribute("content", tagline);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", tagline);
    document
      .querySelector('meta[name="twitter:description"]')
      .setAttribute("content", tagline);
  }

  if (social_media_image) {
    document
      .querySelector('meta[itemprop="image"]')
      .setAttribute("content", social_media_image);
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", social_media_image);
    document
      .querySelector('meta[name="twitter:image"]')
      .setAttribute("content", social_media_image);
  }

  if (head_tracking_codes) {
    const script = document.querySelector("script#head_tacking_codes");
    script.innerHTML = `
        try {
        
          ${head_tracking_codes}
        } catch (error) {
          
        }
      `;
  }

  if (body_tracking_codes) {
    const script = document.querySelector("script#body_tacking_codes");
    script.innerHTML = `
        try {
        
          ${body_tracking_codes}
        } catch (error) {
          
        }
    `;
  }

  if (app_css) {
    const styles = document.querySelector("style#appconfig_styles");
    styles.innerHTML = app_css;
  }
};
